import React from 'react';
import PropTypes from 'prop-types';
import { Layout, SEO } from '@components';
import {
  ContactHeader,
  ContactContentLayout,
} from '@home-page';

function InfoAndContact({ location }) {
  return (
    <Layout location={location}>
      <SEO />
      <ContactHeader />
      <ContactContentLayout />
    </Layout>
  );
}

InfoAndContact.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default InfoAndContact;
